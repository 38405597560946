













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    Location: () => import('@/components/base/InsLocation.vue'),
    InsPhotoGallery: () => import('@/components/business/cms/InsPhotoGallery.vue')
  }
})
export default class InsCmsContent extends Vue {
  CateName: string = '';
  content: object={};
  contentCover: string = '';
  prevData: object={};
  nextData: object={};
  prevShow: boolean = false;
  nextShow: boolean = false;
  pageSize: number = 1000;
  hasPhotoGallery: boolean = false;
  photolist: object[] = [];

  // 获取关于我们cms内容
  getContent () {
    this.hasPhotoGallery = false;
    this.$Api.cms.getContentByDevice({ ContentId: this.id, IsMobile: this.isMobile }).then(result => {
      this.content = result.CMS;
      console.log(result.CMS, '当前文章');
      this.$nextTick(() => {
        if (result.CMS.Title) document.title = result.CMS.Title;
      });
      this.getParentSubCatContents(result.CMS.CatId);
      for (var i = 0; i < result.CMS.CmsControl.length; i++) {
        if (result.CMS.CmsControl.length > 0) {
          this.hasPhotoGallery = true;
          this.photolist = result.CMS.CmsControl[i].ControlImageLis;
          break;
        }
      }
    });
  }
  // 获取上一页和下一页数据
  getParentSubCatContents (catId) {
    this.$Api.cms
      .getSubCatContents({ CatId: catId, PageSize: this.pageSize, IsMobile: this.isMobile })
      .then(result => {
        console.log(result, '父级所有文章');
        if (result.Data) {
          result.Data.forEach((item, index) => {
            result.Data.forEach((item, index) => {
              if (item.Id === parseInt(this.id)) {
                this.prevShow = (index - 1) >= 0;
                this.nextShow = (index + 1) <= result.Data.length - 1;
                this.prevData = result.Data[index - 1];
                this.nextData = result.Data[index + 1];
              };
            });
          });
        }
      });
  }
  created () {
    if ((window as any).__PRERENDER_INJECTED) {
      try {
        let seoData = require('@/sdk/common/SeoData');
        let keywords = document.createElement('meta');
        keywords.setAttribute('name', 'keywords');
        keywords.setAttribute('content', seoData.seoItem['cms' + this.id].keyword);
        document.head.appendChild(keywords);
        let description = document.createElement('meta');
        description.setAttribute('name', 'description');
        description.setAttribute('content', seoData.seoItem['cms' + this.id].description);
        document.head.appendChild(description);
        document.title = seoData.seoItem['cms' + this.id].title;
        document.dispatchEvent(new Event('render-event'));
      } catch (e) {
        console.log('當前頁面無需SEO。');
      }
      this.getContent();
    }
  }
  get page () {
    return Number(this.$route.query.page);
  }
  get id () {
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }
  mounted () {
    this.getContent();
  }
  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.getContent();
  }

  @Watch('id', { deep: true })
  onKeyChange () {
    this.content = {};
    this.getContent();
  }
}
